<template>
    <HeaderVue />
    <v-app>
        <v-row no-gutters class="mb-12 my-row">
            <v-col cols="0" offset-sm="4" sm="4">
            </v-col>
            <v-col cols="12" offset-sm="4" sm="4" class="mt-16 aboutusFont">
                <h1 class="mt-10 mb-10 roboStyle">About Us</h1>
                <h5>
                    Introducing Vienna's premier robotic coffee shop, a nexus where longstanding tradition seamlessly
                    intertwines with cutting-edge innovation.
                </h5>
                <br>
                <h5>
                    Originating from a vision to elevate the Viennese coffee experience, our mission transcends beyond mere
                    brewing. Recognizing the inconsistencies in even the finest coffee establishments, we saw an
                    opportunity—a space where technology meets the age-old craft of coffee brewing. Our passion for coffee,
                    coupled with our dedication to pushing boundaries, culminated in this groundbreaking venture.
                </h5>
                <br>
                <h5>
                    Our state-of-the-art robotic barista, underpinned by meticulous research and precision engineering,
                    exemplifies our commitment to excellence. Our offerings go beyond mere beverages—they're an embodiment
                    of Vienna's rich coffee legacy, crafted with unparalleled precision to resonate with the city's esteemed
                    coffee culture.
                </h5>
                <br>
                <h5>
                    Our endeavor is not just about serving coffee—it's about redefining the coffee-drinking experience.
                    Marrying the ageless allure of traditional Viennese coffee with the prowess of modern technology, we
                    promise a journey from order placement on our advanced GUI to the very last sip that is as seamless as
                    it is memorable.
                </h5>
                <br>
                <h5>
                    Join us at the forefront of Vienna's coffee renaissance. Experience unparalleled taste, unrivaled
                    expertise, and the future of coffee craftsmanship. Your finest coffee experience awaits.
                </h5>
            </v-col>
            <v-col cols="0" offset-sm="4" sm="4" id="about">
            </v-col>
        </v-row>
        <Waveseperator />
        <FooterView />
    </v-app>
</template>

<script>
import HeaderVue from './HeaderVue.vue'
import FooterView from './FooterView.vue';
import Waveseperator from './Waveseperator.vue';
export default {
    name: 'AboutUs',
    components: {
        HeaderVue,
        FooterView,
        Waveseperator,
    },
    methods: {
    }
}
</script>

<style>
.orbitron-font {
    font-family: 'Orbitron', sans-serif;
}

.my-row {
    min-height: 500px;
}

@media (min-width: 768px) {
    .my-row {
        min-height: 630px;
    }
}
</style>