import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import 'vuetify/dist/vuetify.min.css';
import  VueGoogleMaps from '@fawmi/vue-google-maps'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, fa} from 'vuetify/iconsets/fa'


const app = createApp(App);

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        }
    },
  })
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDEf4k7E-MmXUe7HM3eWhs89_SzWsKavAo',
    },
})
app.use(router);
app.use(vuetify);
app.mount('#app');
