<template>
    <v-app id="home">
        <HeaderVue />
        <!-- Title Header-->
        <v-container fluid>
            <v-row class="head">
                <v-col cols="12" md="5">
                    <div style="position: relative;" class="mt-16">
                        <h1 class="text-grey">Vienna's first</h1>
                        <h1 class="text-white">Robot Coffeeshop</h1>
                        <span class="text-grey">Openening: 08.01.2024</span><br />
                        <v-btn tile dark class="text-yellow mt-8" variant="outlined" :to="{ name: 'ProductPage' }">More
                            Information</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                </v-col>
            </v-row>
        </v-container>

        <!--Part 1-->
        <v-container>
            <v-col cols="12" class="mobileMargin">
                <v-row>
                    <v-col cols="0" sm="1">
                    </v-col>
                    <v-col cols="12" sm="6" class="flex-center">
                        <div>
                            <h2 class="titleMain mobileMargin">Artisanal Coffee, Automated</h2>
                            <p>Our innovative robotic coffee brewer masters the art of coffee-making. Whether you prefer a
                                black coffee with no sugar or a cappuccino with a dash of sweetness, our robotic barista
                                caters to your every preference.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div>
                            <img src="../assets/freshcoffee.png" alt="ROBOCUP Logo" width="215" height="250"
                                class="mobileContainer mobileMargin"><span class="text-white">ROBOCUP</span>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="1">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="0" sm="1">
                    </v-col>
                    <v-col cols="12" sm="6" class="flex-center">
                        <div>
                            <h2 class="titleMain mobileMargin">Precision in Every Brew</h2>
                            <p>Driven by advanced robotics and sophisticated algorithms, our coffee brewer epitomizes the
                                pinnacle of modern automation. Every movement, every pour, every steam is calculated to
                                micrometer perfection. Whether you're craving a robust espresso or a velvety cappuccino, our
                                robotic barista ensures consistency, precision, and unparalleled craftsmanship in every cup.
                                Experience the future of coffee brewing, where technology and tradition brew in perfect
                                harmony</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div>
                            <img src="../assets/roboter.png" alt="ROBOCUP Logo" width="150" height="250"
                                class="mobileContainer mobileMargin"><span class="text-white">ROBOCUP</span>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="1">
                    </v-col>
                </v-row>
            </v-col>
        </v-container>

        <!--Part 2-->
        <v-container>
            <v-col cols="12" class="mobileMargin">
                <v-row>
                    <v-col cols="0" sm="1" order="1">
                    </v-col>
                    <v-col cols="12" sm="4" class="order-sm-1 order-3">
                        <div>
                            <img src="../assets/terminal.png" alt="ROBOCUP Logo" width="250" height="200"
                                class="mobileContainer mobileMargin"><span class="text-white">ROBOCUP</span><br><br>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="order-sm-2 order-2 flex-center">
                        <div>
                            <h2 class="titleMain mobileMargin">Effortless Ordering</h2>
                            <p>Experience seamless ordering through our user-friendly GUI. With a
                                range of exquisite
                                coffee types and sizes to choose from, your perfect cup of coffee is just a few
                                clicks
                                away. Browse our menu, make your selection, and proceed to checkout with ease.</p>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="1" order="4">
                    </v-col>
                </v-row>
                <v-row class="mb-10">
                    <v-col cols="0" sm="1" order="1">
                    </v-col>
                    <v-col cols="12" sm="4" class="order-sm-1 order-2">
                        <div>
                            <img src="../assets/payment.png" alt="ROBOCUP Logo" width="300" height="200"
                                class="mobileContainer mobileMargin"><span class="text-white">ROBOCUP</span>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="order-sm-2 order-1 flex-center">
                        <div>
                            <h2 class="titleMain mobileMargin">Swift, Secure Payments</h2>
                            <p>Powered by Stripe's secure payment gateway, you can pay for your favorite brew with
                                complete peace of mind. Simply enter or swipe your credit/debit card details on the
                                Stripe
                                Terminal, and you're all set.</p>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="1" order="4">
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
        <Waveseperator />

        <!-- Google Maps -->
        <v-container>
            <v-col cols="12" class="mt-16 mb-16">
                <v-row>
                    <v-col cols="12" sm="12">
                        <h2 class="mb-10" style="font-family: 'Orbitron', sans-serif; color: #fd7e14;">&#x1F916; Visit us at
                            &#x1F916;</h2>
                        <div class="mapsCustom cardbox shadow-animate">
                            <GoogleMaps></GoogleMaps>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
        <Waveseperator />
        <FooterView />
    </v-app>
</template>

<script>
import HeaderVue from './HeaderVue.vue'
import GoogleMaps from './GoogleMaps.vue'
import FooterView from './FooterView.vue';
import Waveseperator from './Waveseperator.vue';

export default {
    name: 'HomePage',
    components: {
        HeaderVue,
        GoogleMaps,
        FooterView,
        Waveseperator,
    },
    methods: {
        goToProductPage() {
            this.$router.push({ name: 'ProductPage' });
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.v-container {
    padding: 16px 0;
}

.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleMain {
    font-family: 'Orbitron', sans-serif;
    color: #fd7e14;
}

.head {
    font-family: 'Orbitron', sans-serif;
    position: relative;
    text-align: center;
    padding: 12px;
    margin-bottom: 6px;
    height: 400px;
    width: 100%;
    min-width: 105%;
    color: white;
    z-index: 1;
}

.head:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #212529;
    transform: skew(0deg, 5deg);
    z-index: -1;
}

.head:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #212529;
    transform: skew(0deg, -5deg);
    z-index: -1;
}

.mapsCustom {
    margin: 1rem;
    background-color: #212529;
    width: 100%;
}

.multi-glow .cardbox {
    box-shadow: 0 0 10px 5px #9a9a9a,
        0 0 20px 7px #c0c0c0,
        0 0 25px 20px #d9d9d9,
        0 0 30px 25px #ffffff;
}

.cardbox.shadow-animate {
    box-shadow: 0px 0px 20px 10px #9a9a9a;
    animation: shadows 5s infinite;
}

@keyframes shadows {
    0% {
        text-shadow: #9a9a9a 0 0 10px;
        box-shadow: 0px 0px 20px 10px #9a9a9a;
    }

    50% {
        text-shadow: #c0c0c0 0 0 10px;
        box-shadow: 0px 0px 20px 10px #c0c0c0;
    }

    75% {
        text-shadow: #d9d9d9 0 0 10px;
        box-shadow: 0px 0px 20px 10px #d9d9d9;
    }

    100% {
        text-shadow: #9a9a9a 0 0 10px;
        box-shadow: 0px 0px 20px 10px #9a9a9a;
    }
}
</style>
