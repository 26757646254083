<template>
    <v-app-bar app color="dark" dark flat class="px-12">
        <div class="app-bar-container">
            <v-alert color="danger" variant="tonal" title=" Website still in development" class="redAlertBox"
                style="font-size: 10px; padding: 2px;margin-bottom: 4px;margin-top: 4px; padding-left: 10px;"></v-alert>
            <div class="toolbar-content">

                <v-btn icon @click="toggleDrawer" class="d-flex d-md-none">
                    <menu-icon />
                </v-btn>

                <v-btn :to="{ name: 'HomePage' }" color="transparent" class="mobileDifference">
                    <img src="../assets/bean.png" alt="ROBOCUP Logo" width="24" height="32" class="ml-2 mr-2">
                    <span class="text-white">ROBOBREW</span>
                </v-btn>

                <v-spacer />

                <template v-if="!isMobile">
                    <v-btn text :to="{ name: 'HomePage' }" class="text-yellow">Home</v-btn>
                    <v-btn text :to="{ name: 'ProductPage' }" class="text-white">How it works</v-btn>
                    <v-btn text :to="{ name: 'AboutUs' }" class="text-white">About us</v-btn>
                    <v-btn text :to="{ name: 'CoffeeImprint' }" class="text-white">Imprint</v-btn>
                    <v-btn text :to="{ name: 'ContactUs' }" class="text-white">Contact us</v-btn>
                </template>
            </div>
        </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" color="dark" temporary app>
        <v-list>
            <v-list-item :to="{ name: 'HomePage' }" class="text-white">Home</v-list-item>
            <v-list-item :to="{ name: 'ProductPage' }" class="text-white">How it works</v-list-item>
            <v-list-item :to="{ name: 'AboutUs' }" class="text-white">About us</v-list-item>
            <v-list-item :to="{ name: 'CoffeeImprint' }" class="text-white">Imprint</v-list-item>
            <v-list-item :to="{ name: 'ContactUs' }" class="text-white">Contact us</v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
    name: 'HeaderVue',
    components: {
        MenuIcon
    },
    data() {
        return {
            drawer: false,
            isMobile: false
        };
    },
    mounted() {
        this.isMobile = window.innerWidth <= 960;
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth <= 960;
        },
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({ behavior: "smooth" });
        },
        goToHomePage() {
            this.$router.push({ name: 'HomePage' });
        },
        goToProductPage() {
            this.$router.push({ name: 'ProductPage' });
        }
    }
}
</script>

<style scoped>
.v-app-bar {
    height: auto !important;
    min-height: 80px;
}

.app-bar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.toolbar-content {
    display: flex;
    width: 100%;
}

@media (min-width: 768px) {
    .redAlertBox {
        margin-left: 42%;
        margin-right: 42%;
    }
}

@media (max-width: 768px) {
    .toolbar-content {
        display: flex;
        width: 100%;
    }

    .mobileDifference {
        margin-top: 6px;
    }
}
</style>
