<template>
    <HeaderVue />
    <v-app>
        <v-container class="my-row mb-12">
            <h1 class="mt-16 pt-10 roboStyle">The Art of Automated Coffee Ordering</h1>
            <Waveseperator />
            <v-col v-for="(step, index) in steps" :key="index" cols="12" class="mt-16" :id="step.id">
                <v-row class="hover-text-color spin-container mt-2 mb-2" v-if="step.leftClass">
                    <v-col cols="0" sm="2"></v-col>
                    <v-col cols="8" sm="4">
                        <div>
                            <h2 class="mt-16 titleMain">{{ step.title }}</h2>
                            <p>{{ step.description }}</p>
                        </div>
                    </v-col>
                    <v-col cols="4" sm="4">
                        <div class="wrapper">
                            <div class="half-circle right-alignment"></div>
                            <span class="circle-number roboStyle">{{ step.number }}</span>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="2"></v-col>
                </v-row>
                <v-row class="hover-text-color spin-container" v-else>
                    <v-col cols="0" sm="2"></v-col>
                    <v-col cols="4" sm="4">
                        <div class="wrapper">
                            <div class="half-circle "></div>
                            <span class="circle-number roboStyle">{{ step.number }}</span>
                        </div>
                    </v-col>
                    <v-col cols="8" sm="4">
                        <div>
                            <h2 class="mt-16 titleMain">{{ step.title }}</h2>
                            <p>{{ step.description }}</p>
                        </div>
                    </v-col>
                    <v-col cols="0" sm="2"></v-col>
                </v-row>
            </v-col>
        </v-container>
        <Waveseperator class="mt-12"></Waveseperator>
        <FooterView />
    </v-app>
</template>
  
<script>
import HeaderVue from './HeaderVue.vue'
import FooterView from './FooterView.vue';
import Waveseperator from './Waveseperator.vue';

export default {
    name: 'ProductPage',
    components: {
        HeaderVue,
        FooterView,
        Waveseperator,
    },
    data() {
        return {
            steps: [
                {
                    id: 'step1',
                    title: 'Browse Our Menu',
                    description: 'Visit our user-friendly TouchScreen Display and browse through our extensive coffee menu. Explore various\n' +
                        '                coffee types, sizes, and customization options.',
                    leftClass: true,
                    number: 1
                },
                {
                    id: 'step2',
                    title: 'Make Your Selection',
                    description: 'Explore our curated range of almost a duzen coffee varieties. Whether you crave the boldness of espresso or the silkiness of a latte, we have the perfect blend for you. Our menu is crafted to cater to all tastes, from the traditional cappuccino to more unique offerings.',
                    leftClass: false,
                    number: 2
                },
                {
                    id: 'step3',
                    title: 'Review Your Order',
                    description: 'Confirm your coffee selection, size, and any additional customizations. You\'ll see the total\n' +
                        '                price displayed prominently.',
                    leftClass: true,
                    number: 3
                },
                {
                    id: 'step4',
                    title: 'Proceed to Payment',
                    description: 'Click the "Buy Now" button, and you\'ll be prompted to make a payment using our secure Stripe\n' +
                        '                Terminal. Make the payment on the Stripe Terminal with your debit/credit card. Once the payment\n' +
                        '                is confirmed the magic happens.',
                    leftClass: false,
                    number: 4
                },
                {
                    id: 'step5',
                    title: 'Brewing in Progress',
                    description: 'While you wait, our advanced system will provide real-time updates on your coffee\'s brewing\n' +
                        '                progress. Track each step of our robot barista through the window while it crafts your\n' +
                        '                perfect cup.',
                    leftClass: true,
                    number: 5
                },
                {
                    id: 'step6',
                    title: 'Ready for Collection',
                    description: 'You\'ll receive a notification as soon as your coffee is ready. Head over to the\n' +
                        '                  collection counter to pick up your freshly brewed coffee.',
                    leftClass: false,
                    number: 6
                },
                {
                    id: 'step7',
                    title: 'Savor the Taste',
                    description: 'Enjoy your expertly crafted coffee, made just the way you like it. With precise measurements\n' +
                        '                and consistent brewing, every cup is a masterpiece.',
                    leftClass: true,
                    number: 7
                },
                {
                    id: 'step8',
                    title: 'Repeat and Enjoy',
                    description: 'Loved your coffee experience? You can easily order your favorite coffee again or explore\n' +
                        '                  new options from our menu.',
                    leftClass: false,
                    number: 8
                },
            ]
        }
    }
}
</script>
  
<style>
.roboStyle {
    font-family: 'Orbitron', sans-serif;
}

.hover-text-color {
    color: rgb(120, 120, 120);
}

.hover-text-color:hover h2 {
    color: orange;
    font-family: 'Orbitron', sans-serif;
}

.hover-text-color:hover p {
    color: black;
    font-size: larger;
}

.wrapper {
    position: relative;
    width: 600px;
    height: 130px;
}

.half-circle {
    position: absolute;
    top: 80px;
    left: 250px;
    width: 100px;
    height: 50px;
    background-color: transparent;
    border-radius: 100px 100px 0 0;
    border: 5px solid orange;
    border-bottom: none;
    transition: transform 0.5s;
    transform-origin: 50% 100%;
    transform: rotate(270deg);
}

.right-alignment {
    transform: rotate(90deg);
}

.circle-number {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: #8a9297;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin-container:hover .half-circle {
    animation: spin 1.5s linear infinite;
}

.spin-container:hover .circle-number {
    color: black;
    font-size: 50px;
    bottom: -40px;
}

.my-row {
    min-height: 500px;
}

@media (min-width: 768px) {
    .my-row {
        min-height: 630px;
    }

    .mobileContainer {
        margin-right: 2em
    }

    .mobileMargin {
        margin-top: 48px
    }

    .aboutusFont h5 {
        text-align: left;
    }
}

@media (max-width: 768px) {
    .wrapper {
        position: relative;
        width: 90px;
        height: 180px;
    }

    .aboutusFont h5 {
        text-align: left;
        padding: 10px 15px;
    }

    .half-circle {
        position: absolute;
        top: 130px;
        left: 0px;
        width: 100px;
        height: 50px;
        background-color: transparent;
        border-radius: 100px 100px 0 0;
        border: 5px solid orange;
        border-bottom: none;
        transition: transform 0.5s;
        transform-origin: 50% 100%;
        transform: rotate(270deg);
    }

    .right-alignment {
        transform: rotate(90deg);
    }

    .mobileContainer {
        margin: 0 auto;
        display: block;
        text-align: center;
    }

    .mapsCustom {
        margin: 1rem;
        background-color: #212529;
        width: 92% !important;
        margin-right: 10px;
    }

}
</style>
  
  