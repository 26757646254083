<template>

    <GMapMap :center="center" :options="options" :zoom="16" style="width:100%; height: 20rem">
        <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position">
        </GMapMarker>
    </GMapMap>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            center: { lat: 48.218648, lng: 16.327699 },
            markers: [
                {
                    position: {
                        lat: 48.218648, lng: 16.327699
                    },
                }
            ],
            options: {
                mapId: 'f86ac87a3abd4dd8'
            }
        }
    }
}
</script>