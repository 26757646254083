<template>
    <div class="separator-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 30">
            <rect x="0" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 10 20)" />
            <rect x="60" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 60 20)" />
            <rect x="120" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 120 20)" />
            <rect x="180" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 180 20)" />
            <rect x="240" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 240 20)" />
            <rect x="300" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 300 20)" />
            <rect x="360" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 360 20)" />
            <rect x="420" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 420 20)" />
            <rect x="480" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 480 20)" />
            <rect x="540" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 540 20)" />
            <rect x="600" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 600 20)" />
            <rect x="660" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 660 20)" />
            <rect x="720" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 720 20)" />
            <rect x="780" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 780 20)" />
            <rect x="840" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 840 20)" />
            <rect x="900" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 900 20)" />
            <rect x="960" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 960 20)" />
            <rect x="1020" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1020 20)" />
            <rect x="1080" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1080 20)" />
            <rect x="1140" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1140 20)" />
            <rect x="1200" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1200 20)" />
            <rect x="1260" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1260 20)" />
            <rect x="1320" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1320 20)" />
            <rect x="1380" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1380 20)" />
            <rect x="1440" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1440 20)" />
            <rect x="1500" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1500 20)" />
            <rect x="30" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 30 20)" />
            <rect x="90" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 90 20)" />
            <rect x="150" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 150 20)" />
            <rect x="210" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 210 20)" />
            <rect x="270" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 270 20)" />
            <rect x="330" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 330 20)" />
            <rect x="390" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 390 20)" />
            <rect x="450" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 450 20)" />
            <rect x="510" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 510 20)" />
            <rect x="570" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 570 20)" />
            <rect x="630" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 630 20)" />
            <rect x="690" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 690 20)" />
            <rect x="750" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 750 20)" />
            <rect x="810" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 810 20)" />
            <rect x="870" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 870 20)" />
            <rect x="930" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 930 20)" />
            <rect x="990" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 990 20)" />
            <rect x="1050" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1050 20)" />
            <rect x="1110" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1110 20)" />
            <rect x="1170" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1170 20)" />
            <rect x="1230" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1230 20)" />
            <rect x="1290" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1290 20)" />
            <rect x="1350" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1350 20)" />
            <rect x="1410" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1410 20)" />
            <rect x="1470" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1470 20)" />
            <rect x="1530" y="-20" width="15" height="200" fill="#212529" transform="rotate(20 1530 20)" />
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'WaveSeparator',
}
</script>
  
<style>
.separator-container {
    width: 100%;
    background-color: white;
    /* Adjust to match your background color */
}
</style>
  