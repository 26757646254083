<template>
    <v-footer elevation="0" rounded="0" width="100%" class="pa-4">
        <v-container class="backgroundColor">
        <v-row>
            <v-col cols="12" md="4">
                <h4>ABOUT US</h4>
                <p>
                    At Vienna's first robotic coffee shop, we're reimagining the art of coffee-making. Our innovative
                    robotic barista crafts every cup with precision, offering you the perfect blend of traditional Viennese
                    flavors and modern technology. Join us in celebrating the future of coffee, today. Savor the taste,
                    experience the difference. We're excited to serve you. <br><br> This is a Cookie-free Website
                </p>
            </v-col>
            <v-col cols="12" md="4">
                <h4>GET SOCIAL</h4>
                <p style="color: orange;">in Development</p>
            </v-col>
            <v-col cols="12" md="4">
                <h4>CONTACT</h4>
                <p>
                    JONO MULTIMEDIA EXPERT<br>
                    Hernalser Hauptstraße 89<br>
                    1170 Wien<br>
                    Österreich<br><br>
                    +43 660 5258593<br>
                    office@jono.at<br>
                    Monday - Friday: 8:00 a.m. - 7:00 p.m.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn text :to="{ name: 'CoffeeImprint' }" class="bg-warning me-5">IMPRINT</v-btn>
                <v-btn text :to="{ name: 'ContactUs' }" class="bg-warning me-5">CONTACT</v-btn>
            </v-col>
        </v-row>
    </v-container>
    </v-footer>
</template>

<script>
export default {

}
</script>

<style>
.v-footer {
    padding: 8px 0 !important;
}
.backgroundColor{
    background-color: white;
}

</style>