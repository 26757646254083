import HomePage from './components/HomePage.vue'
import ProductPage from './components/ProductPage.vue'
import AboutUs from './components/AboutUs'
import CoffeeImprint from './components/CoffeeImprint'
import ContactUs from './components/ContactUs'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        name: 'HomePage',
        component: HomePage,
        path: '/'
    },
    {
        name: 'ProductPage',
        component: ProductPage,
        path: '/how'
    },
    {
        name: 'AboutUs',
        component: AboutUs,
        path: '/aboutus'
    },
    {
        name: 'CoffeeImprint',
        component: CoffeeImprint,
        path: '/imprint'
    },
    {
        name: 'ContactUs',
        component: ContactUs,
        path: '/contactus'
    },
];

const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
);
export default router
