<template>
    <HeaderVue />
    <v-app>
        <v-row no-gutters class="mb-12 my-row">
            <v-col cols="0" offset-sm="4" sm="4">
            </v-col>
            <v-col cols="12" offset-sm="4" sm="4" class="mt-16">
                <h1 class="orbitron-font">Contact Us</h1>
                <h3>We are available by Email or Phone:</h3>
                <v-expansion-panels>
                    <v-expansion-panel class="mt-4 bg-dark text-white text-center">
                        <v-expansion-panel-title class="justify-center orbitron-font" text="center">
                            <div class="text-center w-100">
                                <h4>Email</h4>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg-white">
                            <div class="p-4">office@jono.at</div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel class="mt-4 bg-dark text-white text-center">
                        <v-expansion-panel-title class="justify-center orbitron-font" text="center">
                            <div class="text-center w-100">
                                <h4>Phone / SMS</h4>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg-white">
                            <div class="p-4">+43 660 5258593</div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="0" offset-sm="4" sm="4">
            </v-col>
        </v-row>
        <Waveseperator></Waveseperator>
        <FooterView />
    </v-app>
</template>

<script>
import HeaderVue from './HeaderVue.vue'
import FooterView from './FooterView.vue';
import Waveseperator from './Waveseperator.vue';
export default {
    name: 'ContactUs',
    components: {
        HeaderVue,
        FooterView,
        Waveseperator,
    },
    methods: {
    },
}
</script>

<style>
.orbitron-font {
    font-family: 'Orbitron', sans-serif;
}

.my-row {
    min-height: 500px;
}

@media (min-width: 768px) {
    .my-row {
        min-height: 630px;
    }
}
</style>