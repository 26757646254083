<template>
    <HeaderVue />
    <v-app>
        <v-container>
            <v-row no-gutters class="mb-12 my-row">
                <v-col cols="0" offset-sm="4" sm="4">
                </v-col>
                <v-col cols="12" offset-sm="4" sm="4" class="">
                    <h1 class="orbitron-font mb-10">Imprint</h1>
                    <h5>
                        JONO MULTIMEDIA EXPERT<br>
                        Ljubisa Jonovic e.U.<br>
                        Hernalser Hauptstraße 89<br>
                        1170 Wien<br>
                        Österreich<br><br>
                        +43 660 5258593<br>
                        office@jono.at<br>
                    </h5>
                </v-col>
                <v-col cols="0" offset-sm="4" sm="4">
                </v-col>
            </v-row>
        </v-container>
        <Waveseperator></Waveseperator>
        <FooterView></FooterView>
    </v-app>
</template>

<script>
import HeaderVue from './HeaderVue.vue'
import FooterView from './FooterView.vue';
import Waveseperator from './Waveseperator.vue';
export default {
    name: 'CoffeeImprint',
    components: {
        HeaderVue,
        FooterView,
        Waveseperator,
    },
    methods: {
    }
}
</script>

<style>
.orbitron-font {
    font-family: 'Orbitron', sans-serif;
}

.my-row {
    min-height: 500px;
}

@media (min-width: 768px) {
    .my-row {
        min-height: 630px;
    }
}
</style>